import { Observable } from "rxjs";
import { AuthInterface } from "@auth/ports/interfaces";
import { RespInterface, UserInterface } from "@common/ports/interfaces";
import { ClientInterface } from "@clients/ports/interfaces";

export abstract class AuthAbstraction {
  abstract login(login: AuthInterface): Observable<RespInterface<UserInterface>>;
  abstract updatePassword(password: string, token: string): Observable<RespInterface<UserInterface>>;
  abstract recoverPassword(email: string): Observable<RespInterface<UserInterface>>;
  
  abstract loginClient(login: AuthInterface): Observable<RespInterface<ClientInterface>>;
  abstract createClient(login: ClientInterface): Observable<RespInterface<string>>;
  abstract recoverPasswordClient(email: string): Observable<RespInterface<string>>;
  abstract updatePasswordClient(password: string, token: string): Observable<RespInterface<string>>;
}
